@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marhey:wght@300");
body {
  margin: 0;
  padding: 0;
  font-size: 0;
  font-family: "Poppins";
  background-color: #f2f2f2;
}
h1 {
  font-family: "Poppins";
  font-size: 3vw;
  text-align: center;
  margin-top: 10px;
}
h2{
  font-family: "Poppins";
  font-size: 2vw;
  text-align: center;
  margin-top: 10px;
}
.arabFont{
  font-family: "Marhey";
  font-size: 3.5vw;
}
.box {
  width: 33.33%;
  display: inline-block;
}
.itemContainer{
  background-color: #97d5fc;
  /* background-color: hsl(160, 60%, 100%); */
  min-height: calc(100vw / 3);
  border-radius: 0.5rem;
  box-shadow: 2px 2px 6px 3px hsl(160, 10%, 90%);
  margin-left: auto;
  margin-right: auto;
  padding: 10px 30px 0px 30px;
  margin-bottom: 40px;
  max-width: 80%;
}
p{
  font-family: "Poppins";
  font-size: 2.5vw;
}
.num{
  font-size: 8vw; 
  text-align: right;
  line-height: 0vw;
  margin-bottom: 2vw;
}
.button-19 {
  appearance: button;
  background-color: #1899D6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins";
  font-size: 1vw;
  letter-spacing: .8px;
  margin-top: 25px;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #1CB0F6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}
.btn-disabled{
  background-color: darkgrey;
  cursor: no-drop;
}
.btn-disabled::after{
  background-color: darkslategrey;
}
.ramTxt{
  float: right;
  margin-top: 2px;
  font-size: 2vw;
}
.iconBox{
  float: right;
}
.completeBg{
  background-color: #d5ffdd;
}
.inprogressBg{
  background-color: #97d5fc;
}
.newBg{
  background-color: #d3d7d8;
}
.footerContainer{
  text-align: center;
  margin: 5px;
}
.progressBarContainer{
  max-width: 80%;
  align-items: center;
  margin: auto;
  margin-bottom: 2vw;
  text-align: center;
}
.CircularProgressbar {
  max-height: 15vw;
}
@media screen and (max-width: 667px) {
  .itemContainer{
    margin-bottom: 5px;
  }
  .ramTxt{
    font-size: 2.5vw;
  }
  .box{
    width: 30%;
    margin-right: 5px;
    margin-left: 7px;
  }
  .itemContainer{
    padding: 15px;
    min-height: calc(100vw / 4);
  }
  .button-19{
    border-width: 0 0 2px;
    padding: 5px 8px;
    margin-top: 5px;
    font-size: 1.5vw;
  }
}

/* modal only */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
a {
  background-color: transparent;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.clickable {
  cursor:pointer;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.clearfix:before,
.clearfix:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}
.clearfix:after,
.modal-footer:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}